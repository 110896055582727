import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetCourseExpandCollapse } from '../../../data-grid-container/courses/types/courses.types';
import {
    manageCourseStrandIsExpanded,
    manageCourseTopicExpandCollapse,
    manageCourseTopicExpandCollapseCheck
} from '../../helpers/courses/course-expand-helpers';
import {
    GridCells,
    GridCounters,
    ToggleActivityGridCheckbox,
    ToggleGridCell,
    ToggleStudentGridCheckbox,
    ToggleTopicGridCheckbox
} from '../../helpers/slice/topic/topic-slice.types';
import {
    ChallengesConstants,
    ChallengesState
} from './challenges.types';
import {
    getDokFilterCourseValues,
    getInitDokCourse,
    setDokOverviewActivityCheckbox
} from './components/challenges-course-filters/challenges-course-filters-helpers';
import {
    DokChallengesOverviewPayload,
    DokFilterCourse, SetChallengesFilterDokPayload
} from './components/challenges-course-filters/components/dok-filter/dok-filter.types';
import {
    defaultLevelFilterValue
} from './components/challenges-course-filters/components/level-filter/level-filter-constants';
import {
    SetChallengesFilterLevelParams
} from './components/challenges-course-filters/components/level-filter/level-filter.types';
import { RootState } from '@config/react-redux/store';
import { StudentListData } from '@containers/activity-router/data-grid-container/student-list/student-list.types';
import { resetGridCells } from '@containers/activity-router/routes/helpers/grid/cells/grid-cells-helpers';
import {
    resetGridCellCounters
} from '@containers/activity-router/routes/helpers/grid/counters/grid-cell-counters/grid-cell-counters';
import { RouteHelpersModalConstants } from '@containers/activity-router/routes/helpers/modal/route-modal-helpers.types';
import {
    getUpdatedFilteredGridData,
    toggleGridCells
} from '@containers/activity-router/routes/helpers/slice/grid/slice-grid-helpers';
import { defaultState } from '@containers/activity-router/routes/helpers/slice/slice-defaults';
import {
    toggleRouteActivityGridCheckbox,
    toggleRouteStudentGridCheckbox,
    toggleRouteTopicGridCheckbox
} from '@containers/activity-router/routes/helpers/slice/slice-helpers';
import {
    manageChallengesCellAssignmentsSelected,
    manageChallengesSelectedAssignments,
    updateChallengesAssignments
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/assignments/selected/challenges-assignments-selected-helpers';
import {
    getChallengesDokFilteredCourse,
    getUpdatedCourseFilterDokInitialState
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-dok-filtered-course';
import {
    getChallengesLevelFilteredCourse
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/course/get-challenges-level-filtered-course';
import {
    getUpdatedDokOverviewFilterValues
} from '@containers/activity-router/routes/learn/challenges/helpers/slice/filtering/challenge-slice-filtering-helpers';
import {
    TransformCourse,
    TransformedChallengesAssignments
} from '@services/gateways/combined-assign-gateway/combined-assign-gateway.types';

const { name } = ChallengesConstants;
const initialState: ChallengesState = {
    ...defaultState,
    course: {
        selected: null,
        filtered: null
    },
    filters: {
        level: defaultLevelFilterValue,
        dok: null,
        isExpanded: true
    },
    assignments: {
        ...defaultState.assignments,
        grid: {}
    },
    modal: null
};

export const challengesSlice = createSlice({
    name,
    initialState,
    reducers: {
        setChallengesCourseSelected (state, action: PayloadAction<TransformCourse>) {
            state.course.selected = action.payload;
            state.course.filtered = action.payload;
        },
        setChallengesCourseIsExpanded (state, action: PayloadAction<SetCourseExpandCollapse>) {
            if (state.course.selected) {
                state.course.selected.topics = manageCourseStrandIsExpanded({
                    course: state.course.selected,
                    payload: action.payload
                });
            }

            if (state.course.filtered) {
                state.course.filtered.topics = manageCourseStrandIsExpanded({
                    course: state.course.filtered,
                    payload: action.payload
                });
            }

            if (state.course.filtered && state.course.selected) {
                if (manageCourseTopicExpandCollapseCheck({
                    course: state.course.filtered || state.course.selected,
                    isExpanded: state.filters.isExpanded
                })) state.filters.isExpanded = !state.filters.isExpanded;
            }
        },
        setChallengesGridCells (state, action: PayloadAction<GridCells>) {
            state.grid.cells = action.payload;
        },
        setChallengesGridCountersInitialValue (state, action: PayloadAction<GridCounters>) {
            state.grid.counters = action.payload;
        },
        toggleChallengesGridCells (state, action: PayloadAction<ToggleGridCell>) {
            const {
                student,
                ...payload
            } = action.payload;
            const {
                gridCells,
                gridCounters
            } = toggleGridCells({
                gridCells: state.grid.cells,
                gridCounters: state.grid.counters,
                studentId: student.userProfileId.toString(),
                ...payload
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;

            state.assignments.selected = manageChallengesCellAssignmentsSelected({
                ...action.payload,
                studentAssignments: state.assignments.grid,
                gridCells: state.grid.cells,
                selectedAssignments: state.assignments.selected
            });
        },
        toggleChallengesStudentGridCheckbox (state, action: PayloadAction<ToggleStudentGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteStudentGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleChallengesTopicGridCheckbox (state, action: PayloadAction<ToggleTopicGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteTopicGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        toggleChallengesActivityGridCheckbox (state, action: PayloadAction<ToggleActivityGridCheckbox>) {
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = toggleRouteActivityGridCheckbox({
                payload: action.payload,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                })
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
        },
        setChallengesAssignmentGrid (state, action: PayloadAction<TransformedChallengesAssignments>) {
            state.assignments.grid = action.payload;
        },
        resetChallengesAssignments (state) {
            state.assignments = {
                grid: {},
                selected: defaultState.assignments.selected
            };
        },
        resetChallengesGrid (state) {
            state.grid.cells = resetGridCells({
                gridCells: state.grid.cells,
                isSelected: false
            });
            state.grid.counters = resetGridCellCounters({
                counters: state.grid.counters
            });
        },
        resetChallengesSelectedAssignments (state) {
            state.assignments.selected = defaultState.assignments.selected;
        },
        setChallengesModal (state, action: PayloadAction<RouteHelpersModalConstants | null>) {
            state.modal = action.payload;
        },
        updateChallengesAssignmentSelected (state, action: PayloadAction<TransformedChallengesAssignments>) {
            state.assignments.selected = updateChallengesAssignments({
                studentAssignments: action.payload,
                selectedAssignments: state.assignments.selected,
                gridCells: state.grid.cells
            });
        },
        setChallengesFilterLevel (state, action: PayloadAction<SetChallengesFilterLevelParams>) {
            const { updatedLevelFilter } = action.payload;
            const {
                updatedCourse,
                hasTopics
            } = getChallengesLevelFilteredCourse({
                course: state.course.selected,
                filterLevel: updatedLevelFilter
            });

            state.filters.dok = getInitDokCourse({
                course: state.course.selected as TransformCourse,
                filterLevel: updatedLevelFilter
            });

            state.course.filtered = updatedCourse;
            state.filters.level = hasTopics ? updatedLevelFilter : defaultLevelFilterValue;
        },
        setChallengesFilterDok (state, action: PayloadAction<SetChallengesFilterDokPayload>) {
            const { dokCourseItems } = action.payload;
            const updatedCourse = getChallengesDokFilteredCourse({
                course: state.course.selected,
                dokCourseItems
            });
            const { updatedLevelFilter } = getDokFilterCourseValues({ dokFilterCourse: dokCourseItems as DokFilterCourse });
            const filteredCourse = getUpdatedCourseFilterDokInitialState(updatedCourse);

            state.filters.dok = dokCourseItems;
            state.filters.level = updatedLevelFilter;
            state.course.filtered = filteredCourse;
        },
        setChallengesOverviewFilter (state, action: PayloadAction<DokChallengesOverviewPayload>) {
            const {
                courseLookup,
                type
            } = action.payload;
            const {
                updatedFilteredCourse,
                updatedDokCourseItems,
                updatedLevelFilter
            } = getUpdatedDokOverviewFilterValues({
                dokFilterCourse: state.filters.dok as DokFilterCourse,
                selectedCourse: state.course.selected,
                courseLookup,
                type
            });
            const {
                gridCells,
                gridCounters,
                assignmentsSelected
            } = setDokOverviewActivityCheckbox({
                courseLookup,
                state: {
                    gridCells: state.grid.cells,
                    gridCounters: state.grid.counters,
                    assignmentsSelected: state.assignments.selected
                },
                manageSelectedAssignments: (params) => manageChallengesSelectedAssignments({
                    ...params,
                    studentAssignments: state.assignments.grid
                }),
                type
            });

            state.grid.cells = gridCells;
            state.grid.counters = gridCounters;
            state.assignments.selected = assignmentsSelected;
            state.filters.dok = updatedDokCourseItems;
            state.filters.level = updatedLevelFilter;
            state.course.filtered = updatedFilteredCourse;
        },
        setChallengesFilteredGridData (state, action: PayloadAction<{ studentListData: StudentListData }>) {
            const { studentListData } = action.payload;
            const {
                updatedGridCells,
                updatedGridCounters,
                updatedSelectedAssignments
            } = getUpdatedFilteredGridData({
                prevGridCells: { ...state.grid.cells },
                studentListData,
                updatedFilteredCourse: state.course.filtered,
                manageSelectedAssignmentsCallback: manageChallengesSelectedAssignments,
                studentAssignments: state.assignments.grid
            });

            state.grid.cells = updatedGridCells;
            state.grid.counters = updatedGridCounters;
            state.assignments.selected = updatedSelectedAssignments;
        },
        setChallengesIsExpanded (state, action: PayloadAction<boolean>) {
            state.filters.isExpanded = action.payload;
            if (state.course.selected) {
                state.course.selected = manageCourseTopicExpandCollapse({
                    course: state.course.selected,
                    isExpanded: action.payload
                });
                if (state.course.filtered) {
                    state.course.filtered = manageCourseTopicExpandCollapse({
                        course: state.course.filtered,
                        isExpanded: action.payload
                    });
                }
            }
        }
    }
});

/**
 * ACTIONS
 */
export const {
    setChallengesCourseSelected,
    setChallengesCourseIsExpanded,
    setChallengesGridCells,
    toggleChallengesStudentGridCheckbox,
    setChallengesGridCountersInitialValue,
    toggleChallengesGridCells,
    toggleChallengesTopicGridCheckbox,
    toggleChallengesActivityGridCheckbox,
    setChallengesAssignmentGrid,
    resetChallengesAssignments,
    resetChallengesGrid,
    resetChallengesSelectedAssignments,
    setChallengesModal,
    updateChallengesAssignmentSelected,
    setChallengesFilterLevel,
    setChallengesFilterDok,
    setChallengesIsExpanded,
    setChallengesOverviewFilter,
    setChallengesFilteredGridData
} = challengesSlice.actions;

/**
 * SELECTORS
 */
export const selectChallengesCourseSelected = (state: RootState) => state[name].course.selected;

export const selectChallengesCourseFiltered = (state: RootState) => state[name].course.filtered;

export const selectChallengesGridCounters = (state: RootState) => state[name].grid.counters;

export const selectChallengesGridCells = (state: RootState) => state[name].grid.cells;

export const selectChallengesAssignmentsGrid = (state: RootState) => state[name].assignments.grid;

export const selectChallengesAssignmentsSelected = (state: RootState) => state[name].assignments.selected;

export const selectChallengesModal = (state: RootState) => state[name].modal;

export const selectChallengesFilterLevel = (state: RootState) => state[name].filters.level;

export const selectChallengesFilterDok = (state: RootState) => state[name].filters.dok;

export const selectChallengesIsExpanded = (state: RootState) => state[name].filters.isExpanded;

export default challengesSlice.reducer;
